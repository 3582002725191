@import url("https://fonts.googleapis.com/css?family=Muli:200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');
@font-face {
  font-family: 'Gilroy';
  src: url("../fonts/Gilroy-ExtraBold.otf") format('opentype');
  font-weight: 800;
}
@font-face {
  font-family: 'Gilroy';
  src: url("../fonts/Gilroy-Light.otf") format('opentype');
  font-weight: 300;
}

body {
  margin: 0;
  font-family: Muli, "Titillium Web", Gilroy, -apple-system, "HelveticaNeue-Light",
  "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande",
  sans-serif;
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}